<template>
    <div id="loading">
        <div>
            <div class="row ">
                <div class="col-md-12">
                    <div class="cont-title">
                        <div class="row">
                            <div class="col-lg-6">
                                <span>Clients</span>
                            </div>
                            <!--<div class="col-lg-6" v-if="global.layout.role_id == 3 && modul != 2">
                                <button :disabled="clients[0] ? clients[0].status_button == 1 ? false : true : true" type="btn" @click="regresRB" class="btn class-button-submit" style="font-size: 13px;padding: 6px;height: 35px;"> <i class="fas fa-sync-alt"></i>  REFRESH STATUS RB</button>
                            </div>-->
                        </div>
                    </div>
                    <div class="cont-search-paginate">
                        <div class="row">
                            <div class="col-lg-3">
                                <div style="display: inline-flex">
                                    <span class="class-span-1">{{ ((start_page - 1)*perpage +1)+'-'+( start_page == ultima_pagina  ? totalclients : start_page*perpage ) }} </span> <span style="padding-top: 7px;"> of </span> <span class="class-span-2"> {{ totalclients }} </span>
                                    <button class="btn class-btn-next-prev" aria-label="Previous" @click="prev(start_page)" :style="start_page != 1 ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                        <i class="fas fa-caret-left"></i>
                                    </button>
                                    <button class="btn class-btn-next-prev" aria-label="Next" @click="next(next_page)"  :style="this.start_page != this.ultima_pagina ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                        <i class="fas fa-caret-right"></i>
                                    </button>
                                    <button id="btnsearch2" class="btn class-btn-refresh" title="Refresh" @click="resertSearch"><i class="fas fa-sync-alt"></i></button>
                                </div>
                            </div>
                            <div class="col-lg-9">
                                <div style="float:right;display:inline-flex">
                                    <input v-if="advance == true" id="searchText" type="text" class="class-input-search" v-model="campo1" @keyup.enter="search(null,null)" style="outline: none">
                                    <button v-if="advance == true" id="btnsearch" class="btn class-btn-next-prev class-color-brow" type="button"  @click="search(null,null)" > <img :src="'/images/icon-search.png'" ></button>
                                    <button class="btn class-button-brow" v-if="searchadvance" id="btn-avance" @click="advanceSearch"> ADVANCED SEARCH <i class="fas fa-caret-right" style="margin-left:5px"></i></button>
                                    <button class="btn class-button-brow" v-if="resetadvance" id="btn-avance" @click="resertSearch"><i class="fas fa-caret-left" style="margin-right:5px"></i>  BASIC SEARCH</button>
                                </div>
                                <br>
                                <br>
                                <div v-if="advance == false" style="display: inline-flex;margin-top: 10px;float:right">
                                    <div class="form-group" style="margin: 0px 5px;">
                                        <label for="">From</label>
                                        <kendo-datepicker
                                            v-model="from"
                                            :min="minDate"
                                            :max="maxDate"
                                            :format="'MM/dd/yyyy'"
                                            class="input-form"
                                            name="date"
                                            id="date"
                                            style="background:#f1f1f1 !important;width:150px;height: auto;display: inherit;">
                                        </kendo-datepicker>
                                    </div>
                                    <div class="form-group" style="margin: 0px 5px;">
                                        <label for="">To</label>
                                        <kendo-datepicker
                                            v-model="to"
                                            :min="minDate"
                                            :max="maxDate"
                                            :format="'MM/dd/yyyy'"
                                            class="input-form"
                                            name="date" 
                                            id="date"
                                            style="background:#f1f1f1 !important;width:150px;height: auto;display: inherit;">
                                        </kendo-datepicker>
                                    </div>
                                    <div v-if="this.modul == 2 || this.modul == 4" class="form-group" style="margin: 0px 5px;">
                                        <label for="">Programs</label>
                                        <select v-model="program" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;" @change="allAdvisors()">                     
                                            <option value="0">All</option>
                                            <option v-for="(item, index) in programs" :key="index" :value="item.id">{{ item.value }}</option>
                                        </select>
                                    </div>
                                    <div v-if="this.global.layout.role_id != 3" class="form-group" style="margin: 0px 5px;">
                                        <label for="">Advisor</label>
                                        <select v-model="advisor" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">                     
                                            <option value="0">All</option>
                                            <option v-for="(item, index) in advisors" :key="index" :value="item.id">{{ item.user_name }}</option>  
                                        </select>
                                    </div>
                                    <div class="form-group" style="margin: 0px 5px;">
                                        <label for="">Status</label>
                                        <select v-model="status" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">                     
                                            <option value="0">All</option>
                                            <option value="1">Active</option>
                                            <option value="2">Inactive</option>
                                            <option value="3">Hold</option>
                                        </select>
                                    </div>
                                    <div class="form-group" style="margin: 0px 5px;">
                                        <label for="">Payment Type</label>
                                        <select v-model="typepayment" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;" @change="typePayement">
                                            <option value="0">All</option>
                                            <option value="1">Automatic</option>
                                            <option value="2">Manual</option>
                                            <option value="3">Others</option>
                                        </select>
                                    </div>
                                    <div class="form-group" style="margin: 0px 5px;" v-if="typepayment == 1">
                                        <label for="">Day Payment</label>
                                        <select v-model="day" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">                     
                                            <option value="0">All</option>
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="15">15</option>
                                            <option value="20">20</option>
                                            <option value="25">25</option>
                                            <option value="30">30</option>
                                        </select>
                                    </div>

                                    <div class="form-group" style="margin-top: 25px;">                                        
                                        <button id="btnsearch" class="btn class-btn-next-prev class-color-brow" type="button"  @click="search(null,null)" > <img :src="'/images/icon-search.png'" ></button>                                        
                                    </div>
                                    <div class="form-group" style="margin-top:25px">
                                        <button id="btnsearch2" class="btn class-btn-next-prev class-color-brow" v-if="resetadvance" title="Clear" @click="resertSearch"><img :src="'/images/icons/CRM-clear.ico'" alt=""></button>
                                    </div>
                                </div>
                                    
                            </div>
                        </div>
                    </div>
                    <div>
                        <div >
                            <table class="table">
                                <tbody id="busq-user">
                                    <tr class="th-bg">
                                        <th>
                                            <span v-if="oneEventClient" @click="search('asc',2)" style=" cursor: pointer;text-decoration:underline">Name </span>
                                            <span v-if="orderClientAsc" @click="search('desc',2)" style=" cursor: pointer;text-decoration:underline">Name <i class="fas fa-long-arrow-alt-up"></i></span>
                                            <span v-if="orderClientDesc" @click="search('asc',2)" style=" cursor: pointer;text-decoration:underline">Name <i class="fas fa-long-arrow-alt-down"></i></span>
                                        </th>
                                        <th>Mobile</th>
                                        <th>
                                            <span v-if="oneEventAccount" @click="search('asc',4)" style=" cursor: pointer;text-decoration:underline">Account </span>
                                            <span v-if="orderAccountAsc" @click="search('desc',4)" style=" cursor: pointer;text-decoration:underline">Account <i class="fas fa-long-arrow-alt-up"></i></span>
                                            <span v-if="orderAccountDesc" @click="search('asc',4)" style=" cursor: pointer;text-decoration:underline">Account <i class="fas fa-long-arrow-alt-down"></i></span>
                                        </th>
                                        <th v-if="modul == 2 || modul == 4">Program</th>
                                        <th>Status</th>
                                        <th v-if="modul != 2">Monthly Payment</th>
                                        <th v-if="modul != 2">Last Paymemt</th>
                                        <th v-if="modul != 2">Payment Type</th>
                                        <th v-if="modul != 2" title="STATUS RECURRING BILLING">SRB</th>
                                        <th v-if="global.layout.role_id != 3">Advisor</th>
                                        <th>
                                            <span v-if="oneEventDate" @click="search('asc',5)" style=" cursor: pointer;text-decoration:underline">Creation Date </span>
                                            <span v-if="orderDateAsc" @click="search('desc',5)" style=" cursor: pointer;text-decoration:underline">Creation Date <i class="fas fa-long-arrow-alt-up"></i></span>
                                            <span v-if="orderDateDesc" @click="search('asc',5)" style=" cursor: pointer;text-decoration:underline">Creation Date <i class="fas fa-long-arrow-alt-down"></i></span>
                                        </th>
                                    </tr>
                                    <tr v-for="(client , index) in clients" :key="index">
                                         <td class="blue-color" >
                                            <a v-if="type == 1 && modul == 3" :href="'/bussiness/clients/account/'+ JSON.parse(client.accounts)[0].account_id">{{client.lead_name}}</a>                                            
                                            <a v-if="type == 1 && modul == 5" :href="'/debtsolution/clients/account/'+ JSON.parse(client.accounts)[0].account_id">{{client.lead_name}}</a>
                                            <a v-if="type == 1 && modul == 6" :href="'/creditexperts/clients/account/'+ JSON.parse(client.accounts)[0].account_id">{{client.lead_name}}</a>
                                            <a v-if="type == 1 && modul == 7" :href="'/boostcredit/clients/account/'+ JSON.parse(client.accounts)[0].account_id">{{client.lead_name}}</a>
                                            <a v-if="type == 1 && modul == 8" :href="'/taxresearch/clients/account/'+ JSON.parse(client.accounts)[0].account_id">{{client.lead_name}}</a>
                                            <a v-if="type == 0" :href="'/crm/clients/edit/'+ client.lead_id">{{client.lead_name}}</a>
                                            <a v-if="type == 1 && modul == 4" :href="'/administration/clients/edit/'+ client.lead_id">{{client.lead_name}}</a>
                                        </td>
                                         <td class="gris-color">{{client.mobile}}</td>
                                         <td >
                                             <ul style="padding-left: 0px; margin-bottom: 0px"> 
                                                <li v-for="(account , index) in JSON.parse(client.accounts)" :key="index" style="list-style: none;">
                                                    <span v-if="type == 1 && modul == 3" >{{account.account}}</span>
                                                    <a v-if="type == 1 && modul == 4" :href="'/administration/clients/account/'+ account.account_id">{{account.account}}</a>
                                                    <span v-if="type == 1 && modul == 5" >{{account.account}}</span>
                                                    <span v-if="type == 1 && modul == 6" >{{account.account}}</span>
                                                    <span v-if="type == 1 && modul == 7" >{{account.account}}</span>
                                                    <span v-if="type == 1 && modul == 8" >{{account.account}}</span>     
                                                    <span v-if="type == 0">{{account.account}}</span>
                                                </li> 
                                            </ul>
                                         </td>
                                         <td v-if="modul == 2 || modul == 4">
                                             <ul style="padding-left: 0px; margin-bottom: 0px"> 
                                                <li v-for="(account , index) in JSON.parse(client.accounts)" :key="index" style="list-style: none;">{{ account.program}}</li> 
                                            </ul>
                                         </td>
                                         <td >
                                             <ul style="padding-left: 0px; margin-bottom: 0px"> 
                                                <li v-for="(account , index) in JSON.parse(client.accounts)" :key="index" style="list-style: none;">
                                                    <span v-if="account.status == 1" style="color:#00CC00;text-transform: uppercase;font-weight: bold;">Active</span>
                                                    <span v-if="account.status == 2" style="color:#ffc107;text-transform: uppercase;font-weight: bold;">Hold</span>
                                                    <span v-if="account.status == 0" style="color:#FF0000;text-transform: uppercase;font-weight: bold;">Inactive</span>
                                                </li> 
                                            </ul>
                                         </td>                                         
                                         <td v-if="modul != 2">
                                             <ul style="padding-left: 0px; margin-bottom: 0px"> 
                                                <li v-for="(account , index) in JSON.parse(client.accounts)" :key="index" style="list-style: none;">$ {{ account.monthly_amount}}</li> 
                                            </ul>
                                         </td>
                                         <td v-if="modul != 2">
                                             <ul style="padding-left: 0px; margin-bottom: 0px"> 
                                                <li v-for="(account , index) in JSON.parse(client.accounts)" :key="index" style="list-style: none;"><span v-if="account.last_payment != null">{{ account.last_payment}}</span></li> 
                                            </ul>
                                         </td>
                                         <td v-if="modul != 2">
                                             <ul style="padding-left: 0px; margin-bottom: 0px"> 
                                                <li v-for="(account , index) in JSON.parse(client.accounts)" :key="index" style="list-style : none">
                                                    <img title="Automatic" v-if="account.type_payment == 'PA' && account.status_payment == '1'" :src="'/images/icons/paymenttype-auto.ico'" alt="">
                                                    <img title="Automatic" v-if="account.type_payment == 'PA' && account.status_payment == '0'" :src="'/images/icons/paymenttype-auto-no.ico'" alt="">
                                                    <img title="Manual" v-if="account.type_payment == 'MA'" :src="'/images/icons/paymenttype-manual.ico'" alt="">
                                                    <img title="Others" v-if="account.type_payment == 'OT'" :src="'/images/icons/paymenttype-others.ico'" alt="">
                                                    <span v-if="account.day_payment != null">({{account.day_payment}})</span>
                                                </li>
                                            </ul>
                                         </td>
                                         <td v-if="modul != 2">
                                             <ul style="padding-left: 0px; margin-bottom: 0px"> 
                                                <li v-for="(account , index) in JSON.parse(client.accounts)" :key="index" :style="account.srb == 'ACTIVE' ? 'list-style: none;color:#00CC00' :'list-style: none;color:#FF0000'"><span title="STATUS RECURRING">{{ account.srb}}</span></li> 
                                            </ul>
                                         </td>                                         
                                         <td v-if="global.layout.role_id != 3">
                                             <ul style="padding-left: 0px; margin-bottom: 0px"> 
                                                <li v-for="(account , index) in JSON.parse(client.accounts)" :key="index" style="list-style: none;">{{ account.advisor_name}}</li> 
                                            </ul>
                                         </td>
                                         <td class="gris-color">
                                             <ul style="padding-left: 0px; margin-bottom: 0px"> 
                                                <li v-for="(account , index) in JSON.parse(client.accounts)" :key="index" style="list-style: none;">{{ account.created_at | myGlobal}}</li> 
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>                            
                        </div>
                    </div>
                    <div class="cont-search-paginate">
                        <div class="row">
                            <div class="col-lg-3">
                                <div>
                                    <span class="class-span-1">{{ ((start_page - 1)*perpage +1)+'-'+( start_page == ultima_pagina  ? totalclients : start_page*perpage ) }} </span> <span> of </span> <span class="class-span-2"> {{ totalclients }} </span>
                                    <button class="btn class-btn-next-prev" aria-label="Previous" @click="prev(start_page)" :style="start_page != 1 ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                        <i class="fas fa-caret-left"></i>
                                    </button>
                                    <button class="btn class-btn-next-prev" aria-label="Next" @click="next(next_page)"  :style="this.start_page != this.ultima_pagina ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                        <i class="fas fa-caret-right"></i>
                                    </button>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:['global','type','modul'],
    data() {
        return {
            clients: [],
            campo1: '',
            advance : true,
            min: new Date(1950, 0, 1, 0, 0, 0),
            max: new Date(2049, 11, 31, 24, 0, 0),
            minDate: new Date(1000,1,1),
            maxDate: new Date(2050,9,1),
            searchadvance: true,
            resetadvance: false,
            from: '',
            to: '',
            program:null,
            programs:[],
            iduser: this.global.layout.id,
            start_page: '',
            next_page: '',
            perpage:'',
            ultima_pagina :'',
            totalclients:'',
            orderDate: true,
            oneEventClient: true,
            orderClientAsc:false,
            orderClientDesc:false,
            oneEventDate: true,
            orderDateAsc:false,
            orderDateDesc:false,
            oneEventAccount:true,
            orderAccountAsc:false,
            orderAccountDesc:false,
            status:'',
            advisor:'',
            advisors:[],
            day:'',
            typepayment: ''
        }
    },
    mounted() {
        console.log(this.modul)
        var boton = document.getElementById('app');
        boton.classList.add('preloader');        
        switch (this.modul) {
            case 2: this.program = null; break;
            case 3: this.program = 1; break;
            case 4: this.program = null; break;
            case 5: this.program = 4; break;
            case 6: this.program = 3; break;
            case 7: this.program = 2; break;
            case 8: this.program = 5; break;
        }
        this.allAdvisors();
    },
    methods: {
        typePayement(){
            console.log(this.day)
            if(this.typepayment != 1){
                this.day = ''
            }
            console.log(this.day)
        },
        regresRB(){            
            swal.fire({
                title: 'Update status ?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#ab9220',
                cancelButtonColor: '#8f9194',
                confirmButtonText: 'Yes'
                }).then((result) => {
                    if (result.value) {
                        var boton = document.getElementById('app');
                        boton.classList.add('preloader')
                        axios.post('/refreshrb',{                
                            user_id:this.global.layout.id,                
                        })
                        .then(response => {
                            if(response.status == 200) {                    
                                var boton = document.getElementById('app');
                                boton.classList.remove('preloader')
                                swal.fire({
                                    type: 'success',
                                    title: 'Operation Finish'
                                }).then( (res) => {
                                    if(res){
                                        this.search(null,null)
                                        boton.classList.remove('preloader')
                                        }
                                    });
                            }
                        })
                        .catch(err => {
                            console.error(err); 
                        })
                    }
                })
        },
        advanceSearch(){
            this.advance = false
            this.searchadvance = false
            this.resetadvance =  true
            this.campo1 = ''
            var x = document.getElementById('btnsearch')
            x.classList.add('margin-top-advance')
        },
        resertSearch(){
            this.campo1 = null
            this.advance = true
            this.searchadvance = true
            this.resetadvance =  false
            this.from = ''
            this.to = ''
            this.program = null
            this.advisor = null
            this.day = ''
            this.typepayment = ''
            this.search(null,null)
            var x = document.getElementById('btnsearch')
            x.classList.remove('margin-top-advance')
        },
        prev(start_p){
            var newval = start_p - 1;
            var boton = document.getElementById('app');
            boton.classList.add('preloader')
            axios.post('/api/clients?page='+newval,{
                    text: this.campo1,
                    from: this.from,
                    to: this.to,
                    program: this.program,
                    orderby: this.dato2 == null ? 5 : this.dato2,
                    order: this.dato1 == null ? 'desc' : this.dato1,
                    status: this.status,
                    advisor:this.advisor,
                    type: this.typepayment,
                    day: this.day
            }).then(response => {
                if(response.status == 200){
                    this.clients = response.data.data                
                    this.start_page =  response.data.current_page;
                    this.perpage = response.data.per_page;
                    if(this.start_page == this.ultima_pagina){
                        this.next_page = this.start_page;
                    }else{
                        this.next_page = this.start_page+1;
                    } 
                    this.ultima_pagina = response.data.last_page;
                    this.totalclients = response.data.total;                    
                    setTimeout(() => {
                        var boton = document.getElementById('app');
                        boton.classList.remove('preloader')
                    }, 50); 
                }
            });
        },  
        next(next){
            var boton = document.getElementById('app');
            boton.classList.add('preloader')
            axios.post('/api/clients?page='+next,{
                    text: this.campo1,
                    from: this.from,
                    to: this.to,
                    program: this.program,
                    orderby: this.dato2 == null ? 5 : this.dato2,
                    order: this.dato1 == null ? 'desc' : this.dato1,
                    status: this.status,
                    advisor:this.advisor,
                    type: this.typepayment,
                    day: this.day
            }).then(response => {
                if(response.status == 200){
                    this.clients = response.data.data                                     
                    this.start_page =  response.data.current_page;
                    this.perpage = response.data.per_page;                  
                    this.ultima_pagina = response.data.last_page;
                    this.totalclients = response.data.total;
                    if(this.start_page == this.ultima_pagina){
                        this.next_page = this.start_page;
                    }else{
                        this.next_page = this.start_page+1;
                    }
                    setTimeout(() => {
                        var boton = document.getElementById('app');
                        boton.classList.remove('preloader')
                    }, 50); 
                }
            });
        },
        search(dato1 , dato2){
            this.dato1 = dato1
            this.dato2 = dato2 
            if(dato2 == 2) {
                if(dato1 == "desc"){
                    this.oneEventClient = false
                    this.orderClientDesc = true
                    this.orderClientAsc = false
                }else{
                    this.orderClientAsc = true
                    this.orderClientDesc = false
                    this.oneEventClient = false
                }
                this.oneEventDate = true
                this.orderDateDesc = false
                this.orderDateAsc = false
            }
            if(dato2 == 5) {
                if(dato1 == "desc"){
                    this.oneEventDate = false
                    this.orderDateDesc = true
                    this.orderDateAsc = false
                }else{
                    this.orderDateAsc = true
                    this.orderDateDesc = false
                    this.oneEventDate = false
                }
                this.oneEventClient = true
                this.orderClientAsc = false
                this.orderClientDesc = false
            }
            if(dato2 == 4) {
                if(dato1 == "desc"){
                    this.oneEventAccount = false
                    this.orderAccountDesc = true
                    this.orderAccountAsc = false
                }else{
                    this.orderAccountAsc = true
                    this.orderAccountDesc = false
                    this.oneEventAccount = false
                }
                /*this.oneEventAccount = true
                this.orderClientAsc = false
                this.orderClientDesc = false*/
            }
                       
            var module = 0
            switch (this.modul) {
                case 2: module = this.program; break;
                case 4: module = this.program; break;
                case 3: module = 1; break;
                case 7: module = 2; break;
                case 6: module = 3; break;
                case 5: module = 4; break;
                case 8: module = 5; break;
            }
            
            if(this.global.layout.role_id == 1 || this.global.layout.role_id == 2){
                this.advisor = this.advisor                
            }else{
                this.advisor = this.global.layout.id
            }

                axios.post('/api/clients',{
                    text: this.campo1,
                    from: this.from,
                    to: this.to,
                    program: module,
                    orderby: this.dato2 == null ? 5 : this.dato2,
                    order: this.dato1 == null ? 'desc' : this.dato1,
                    status: this.status,
                    advisor:this.advisor,
                    type: this.typepayment,
                    day: this.day
                })
                .then(response => {
                    if(response.status == 200){                    
                        this.clients = response.data.data
                        this.start_page =  response.data.current_page;
                        this.perpage = response.data.per_page;
                        this.next_page = this.start_page+1;
                        this.ultima_pagina = response.data.last_page;
                        this.totalclients = response.data.total;
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                    }
                })
        },
        allPrograms(){
            axios.get('/api/programs')
                .then(response => {
                    this.programs =  response.data 
                });
        },
        allAdvisors(){
           // console.log(this.program)
            var module = -1
            if(this.program == 0){
                module = 0
            }else{
                switch (this.program) {                
                    case 1: module = 3; break;
                    case 2: module = 7; break;
                    case 3: module = 6; break;
                    case 4: module = 5; break;
                    case 5: module = 8; break;
                }
            }
            this.advisor = null
            axios.post('/api/usersprograms',{id:module})
                .then(response => {
                    this.advisors =  response.data 
                });
        },

    },
    created() {
        this.search(null,null);
        this.allPrograms();        
    },
}
</script>