<template lang="pug">
div
    #modal_event.modal-event.estilo-modal.box-login(style="width: 800px !important;z-index: 7;")
        .row.title-modal-edit
            .col-lg-12.col-md-12(style="text-align:center")
                h3 ADD TASK
                i.fas.fa-times.icon-close-target(@click="closeModal")
        div
            #modalBody.modal-body
                div 
                    div.campos-detail-modal.row(style="margin: 0;")                        
                        div.col-lg-6
                            div.form-group.class-inline.row
                                div.class-campo-icon.col-md-4 Client
                                div.class-campo-text.col-md-8
                                    span(name="start" id="date") {{ nameClient }}
                        br
                        br
                        .col-lg-12.col-md-12
                            .form-group.row
                                .col-md-4
                                    label Subject #[span(style="color:red" v-if="errorSubj") Required ]
                                .col-md-8(style="display:inherit")
                                    input.input-form(:id="'campo'+1"  type="text"  step="any" v-model="subject")
                        .col-lg-12.col-md-12
                            .form-group.row
                                .col-md-4
                                    label Due Date #[span(style="color:red" v-if="errorDate") Required ]
                                .col-md-8(style="display:inherit")
                                    .row
                                        .col-md-6
                                            kendo-datepicker(
                                                v-model="date"
                                                :min="minDate"
                                                :max="maxDate"
                                                :format="'MM/dd/yyyy'"
                                                class="input-form"
                                                name="date"
                                                id="date"
                                                style="background:white !important;width:150px;height: auto;display: inherit;")
                                        .col-md-6
                                            kendo-timepicker(
                                                :min="min"
                                                :max="max"
                                                :value="value"   
                                                style="background: #f1f1f1 !important"
                                                v-model="hour" 
                                                name="from" id="from" 
                                                class="input-form"
                                                placeholder="Hour"
                                                :format = "'HH:mm'")
                        .col-lg-12.col-md-12
                            .form-group.row
                                .col-md-4
                                    label Content #[span(style="color:red" v-if="errorContent") Required ]
                                .col-md-8
                                    textarea.input-form(style="height:120px" v-model="content")
            .modal-footer
                .form-group
                    button.btn.btn-success.btn-color-green(type="button" @click="savePayment"  style="font-size: 13px;padding: 6px;height: 35px;") #[i.fas.fa-arrow-up] SUBMIT             
</template>

<script>
    export default {
        props:['nameClient','idaccount','session','addtaskmodal'],
        data() {
            return {
                min: new Date(1950, 0, 1, 0, 0, 0),
                max: new Date(2049, 11, 31, 24, 0, 0),
                minDate: new Date(1000,1,1),
                maxDate: new Date(2050,9,1),
                value: "00:00 AM",
                id:this.addtaskmodal.id,
                subject: this.addtaskmodal.subject,
                date:this.addtaskmodal.date,
                hour:this.addtaskmodal.hour,
                content:this.addtaskmodal.content,
                errorSubj:false,
                errorDate:false,
                errorContent:false,
            }
        },
        mounted() {
            console.log(this.addtaskmodal)
        },
        methods: {
            closeModal(){
                this.$emit('click',false)
            },
            savePayment(){
                if(this.subject == ''){
                    this.errorSubj = true
                }else{
                    this.errorSubj = false
                    if(this.date == '' && this.hour == ''){
                        this.errorDate = true
                    }else if(this.date != '' && this.hour == ''){
                        this.errorDate = true
                    }else if(this.date == '' && this.hour != ''){
                        this.errorDate = true
                    }else if(this.date != '' && this.hour != ''){
                        this.errorDate = false
                        if(this.content == ''){
                            this.errorContent = true
                        }else{
                            this.errorContent = false
                            this.axiosEnv()
                        }
                    }
                }
            },
            axiosEnv(){                
                var x = document.getElementById('modal_event')
                x.classList.add('preloader-new')
                swal.fire({
                    title: 'Are you sure ?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#ab9220',
                    cancelButtonColor: '#8f9194',
                    confirmButtonText: 'Yes'
                    }).then((result) => {
                        if (result.value) {
                                var btn = document.getElementById('app')
                                btn.classList.add('preloader')
                                axios.post('/api/addtask',{
                                    id: this.idaccount,
                                    idtask: this.id,
                                    subject: this.subject,
                                    date: this.date,
                                    hour: this.hour,
                                    content: this.content,
                                    user_id: this.session
                                })
                                .then(response => {
                                    if(response.status == 200){
                                        swal.fire({
                                            type: 'success',
                                            title: 'Operation Finish'
                                            }).then( (res) => {
                                                if(res){
                                                    btn.classList.remove('preloader')
                                                    this.$emit('click',false)
                                                    }
                                                });                        
                                    }
                                })
                                .catch(err => {
                                    console.error(err); 
                                })
                        }else{
                            var x = document.getElementById('modal_event')
                            x.classList.remove('preloader-new')
                        }
                    })
            }
        },
        created() {
            
        },
    }
</script>