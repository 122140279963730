<template>
    <div>
        <div id="loading">
            <div>
                <div class="row ">
                    <div class="col-md-12">
                        <div class="cont-title">
                            <div class="row">
                                <div class="col-lg-6">
                                    <span>Clients</span>
                                </div>
                            </div>
                        </div>
                        <div class="cont-search-paginate">
                            <div class="cont-user-info">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="border-campo-user">
                                            <span class="title-modal">Client Information</span>
                                            <div class="row" style="padding: 20px">
                                                <div class="col-lg-4">
                                                    <div style="color:#B59200">
                                                        <div class="form-group class-inline row">
                                                            <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                <span>Client name</span>
                                                            </div>
                                                            <div class="class-campo-text col-md-8">
                                                                <span name="start" id="date"> {{client.client_name}} </span>
                                                            </div>
                                                        </div>
                                                        <div class="form-group class-inline row">
                                                            <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                <span>Account</span>
                                                            </div>
                                                            <div class="class-campo-text col-md-8">
                                                                <span name="start" id="date"> {{client.account}} </span>
                                                            </div>
                                                        </div>
                                                        <div class="form-group class-inline row">
                                                            <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                <span>Advisor</span>
                                                            </div>
                                                            <div class="class-campo-text col-md-8">
                                                                <select style="width:100%" v-if="advisorstate  == true" name="" id="" v-model="advisor" class="input-form">
                                                                    <option :value="item.id" v-for="(item, index) in advisors" :key="index">{{item.user_name}}</option>
                                                                </select>
                                                                <button v-if="advisorstate == true" class="btn class-button-green-payment" @click="saveAdvisor">Save</button>
                                                                <button v-if="advisorstate == true" class="btn class-button-red-payment" @click="changeAdvisor">Cancel</button>
                                                                <span v-if="advisorstate == false" name="start" id="date"> {{client.advisor_name}} </span>                                                                
                                                                <i title="Edit" @click="changeAdvisor" class="fas fa-pen" :style="advisorstate == false ? 'position: absolute;right: 25px;top: 10px;':'position: absolute;right: 25px;bottom: 10px;opacity:1 !important'"></i>
                                                                <i title="History" @click="historyAdvisor" class="fas fa-list" :style="advisorstate == false ? 'position: absolute;right: 5px;top: 10px;':'position: absolute;right: 5px;bottom: 10px;opacity:1 !important'"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div style="" class="row">
                                                        <div class="col-md-12">
                                                            <div class="form-group class-inline row">
                                                                <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                    <span >Status</span>
                                                                </div>
                                                                <div class="class-campo-text col-md-8">
                                                                    <select style="width:90%" v-if="statusstate  == true" name="" id="" v-model="status" class="input-form">
                                                                        <option value="1">Active</option>
                                                                        <option value="0">Inactive</option>
                                                                        <option value="2">Hold</option>
                                                                    </select>
                                                                    <button v-if="statusstate == true" class="btn class-button-green-payment" @click="saveStatus">Save</button>
                                                                    <button v-if="statusstate == true" class="btn class-button-red-payment" @click="changeStatus">Cancel</button>
                                                                    <span v-if="statusstate == false" name="start" id="date"> {{client.status == 1 ? 'Active' : client.status == 2 ? 'Hold' : 'Inactive'}} </span>
                                                                    <i title="Edit" @click="changeStatus" class="fas fa-pen" :style="statusstate == false ? 'position: absolute;right: 25px;top: 10px;':'position: absolute;right: 25px;bottom: 10px;opacity:1 !important'"></i>
                                                                    <i title="History" @click="historyStatus" class="fas fa-list" :style="statusstate == false ? 'position: absolute;right: 5px;top: 10px;':'position: absolute;right: 5px;bottom: 10px;opacity:1 !important'"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="form-group class-inline row">
                                                                <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                    <span>Start date</span>
                                                                </div>
                                                                <div class="class-campo-text col-md-8">
                                                                    <span name="start" id="date">{{ client.start_date | myGlobal }} </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="form-group class-inline row">
                                                                <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                    <span>Captured by</span>
                                                                </div>
                                                                <div class="class-campo-text add-class-campo-text col-md-8">
                                                                    <span name="start" id="date"> {{ client.captured_name }} </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="form-group class-inline row">
                                                                <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                    <span>Seller</span>
                                                                </div>
                                                                <div class="class-campo-text add-class-campo-text col-md-8">
                                                                    <span name="start" id="date"> {{client.seller_name}} </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div style="color:#B59200">
                                                        <div class="form-group class-inline row">
                                                            <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                <span>Initial payment</span>
                                                            </div>
                                                            <div class="class-campo-text col-md-8">
                                                                <img title="Initial Payment" :src="'/images/icons/CRM--initialpayment-ON.ico'" alt=""  style="cursor:pointer" @click="modalPayment()">
                                                            </div>
                                                        </div>
                                                        <div class="form-group class-inline row">
                                                            <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                <span>Contract fee</span>
                                                            </div>
                                                            <div class="class-campo-text col-md-8">
                                                                <img title="Contract Fee" :src="'/images/icons/CRM--contractfee-ON.ico'" alt=""  style="cursor:pointer" @click="modalContract()">
                                                            </div>
                                                        </div>
                                                        <div class="form-group class-inline row">
                                                            <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                <span>Monthly payment</span>
                                                            </div>
                                                            <div class="class-campo-text col-md-8">
                                                                <span>$ {{client.monthly_amount}} </span> <span>|</span>
                                                                <img title="Automatic" v-if="client.type_payment == 'PA' && client.rbp == '1'" :src="'/images/icons/paymenttype-auto.ico'" alt="">
                                                                <img title="Automatic" v-if="client.type_payment == 'PA' && client.rbp == '0'" :src="'/images/icons/paymenttype-auto-no.ico'" alt="">
                                                                <img title="Manual" v-if="client.type_payment == 'MA'" :src="'/images/icons/paymenttype-manual.ico'" alt="">
                                                                <img title="Others" v-if="client.type_payment == 'OT'" :src="'/images/icons/paymenttype-others.ico'" alt="">
                                                                <span v-if="client.day_payment != null">({{client.day_payment}})</span>
                                                                <i @click="changeMonthly" class="fas fa-pen" style="position: absolute;right: 25px;top: 10px;"></i>
                                                                <i @click="historyMonthly" class="fas fa-list" style="position: absolute;right: 5px;top: 10px;"></i> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cont-user-info">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="border-campo-user">
                                            <span class="title-modal">Payment Report</span>
                                            <div class="row" style="padding: 20px">
                                                <div class="col-lg-5">                                                    
                                                    <div class="form-group">
                                                        <table class="table">
                                                            <tbody id="busq-user">
                                                                <tr class="th-bg">
                                                                    <th>Date</th>
                                                                    <th>Fee or Charge</th>
                                                                    <th>Amount</th>
                                                                </tr>
                                                                <tr v-for="(item, index) in listcharges" :key="index">
                                                                    <td>{{item.created_at | myGlobal}}</td>
                                                                    <td>{{item.fee}}</td>
                                                                    <td>$ {{item.amount}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div class="form-group">                                            
                                                        <button style="float:left" @click="changeCharge" class="btn class-button-green"><img :src="'/images/icon-add.png'" style="margin-right: 5px;width: 10%;margin-top: -3px;"> ADD CHARGE</button>
                                                    </div>
                                                </div>
                                                <div class="col-lg-7">
                                                    <div class="form-group">
                                                        <table class="table">
                                                            <tbody id="busq-user">
                                                                <tr class="th-bg">
                                                                    <th>Date</th>
                                                                    <th>Type</th>
                                                                    <th>Id Transaction</th>
                                                                    <th>Amount</th>
                                                                    <th>Method</th>
                                                                </tr>
                                                                <tr v-for="(item, index) in listpayments" :key="index">
                                                                    <td>{{item.settlement_date | myGlobal}}</td>
                                                                    <td>{{item.type}}</td>
                                                                    <td>{{item.transaction_id}}</td>
                                                                    <td>$ {{item.amount}}</td>
                                                                    <td>{{item.method_payment}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div class="form-group">
                                                        <button @click="addCredit()" style="float:left;width: 140px" class="btn class-button-green"><img :src="'/images/icon-add.png'" style="margin-right: 5px;width: 10%;margin-top: -3px"> ADD CREDIT</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" style="margin:0">
                                                <div class="col-lg-4">
                                                    <div class="form-group class-inline row">
                                                        <div class="class-campo-icon add-class-campo-icon col-md-6">
                                                            <span>Total Fees an Charges</span>
                                                        </div>
                                                        <div class="class-campo-text col-md-6">
                                                            <span name="start" id="date">$ {{client.total_charge}} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">                                                    
                                                    <div class="form-group class-inline row">
                                                        <div class="class-campo-icon add-class-campo-icon col-md-6">
                                                            <span>Total Payments</span>
                                                        </div>
                                                        <div class="class-campo-text col-md-6">
                                                            <span name="start" id="date">$ {{client.total_payments}} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="form-group class-inline row">
                                                        <div style="background:#908035" class="class-campo-icon add-class-campo-icon col-md-6">
                                                            <span>Account Balance</span>
                                                        </div>
                                                        <div style="border-color: #908035;" class="class-campo-text col-md-6">
                                                            <span name="start" id="date">$ {{client.balance}} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal-payment v-if="openPayment" @click="closePayment" v-bind:payments="this.payments" :nameProgram="this.client.program_name" :nameClient="this.client.client_name" :type="1" :editmodal="false" :statusSale="4" :sessionId="0" :valorInitalPaymetn="0"></modal-payment>
        <modal-contract v-if="openContract" @click="closeContract" v-bind:contractsales="this.contractsales" :nameProgram="this.client.program_name" :nameClient="this.client.client_name" :type="1" :editmodal="false" :statusSale="4" :valorInitalPaymetn="0"></modal-contract>
        <modal-monthly-edit  v-if="openMonthly" @click="closeMonthly" :lead_id="this.client.lead_id" :nameProgram="this.client.program_name" :nameClient="this.client.client_name" :id="this.client.id" :session="this.global.layout.id" @actuals="actualityMonthly"></modal-monthly-edit>
        <modal-add-charge v-if="openCharge" @click="closeCharge" :nameProgram="this.client.program_name" :nameClient="this.client.client_name" :lead_id="this.client.lead_id" :id="this.client.id" :account="this.client.account" :session="this.global.layout.id" @dato="globalfunction" :cantcards="this.client.cards"></modal-add-charge>
        <modal-history-advisor v-if="openHistoryAdvisor" @click="closeAdvisor" :nameProgram="this.client.program_name" :nameClient="this.client.client_name" :id="this.global.id"></modal-history-advisor>
        <modal-history-status v-if="openHistoryStatus" @click="closeStatus" :nameProgram="this.client.program_name" :nameClient="this.client.client_name" :id="this.global.id"></modal-history-status>
        <modal-history-monthly v-if="openHistoryMonthly" @click="closeMonthlyHistory" :nameProgram="this.client.program_name" :nameClient="this.client.client_name" :id="this.global.id"></modal-history-monthly>
        <modal-credit v-if="openCredit" @click="closeCredit" :nameProgram="this.client.program_name" :nameClient="this.client.client_name" :type="1" :editmodal="false" :account="this.global.id" :statusSale="4" :sessionId="this.global.layout.id" :valorInitalPaymetn="0"></modal-credit>
    </div>
</template>

<script>
export default {
    props:['global'],
    data() {
        return {
            openContract: false,
            openPayment: false,
            openMonthly: false,
            openCharge: false,
            openHistoryAdvisor:false,
            openHistoryStatus:false,
            openHistoryMonthly:false,
            payments:[],
            client:[],
            advisors: [],
            advisor: '',
            advisorstate: false,
            status:'',
            statusstate: false,
            listpayments: [],
            listcharges: [],
            openCredit:false,
            contractsales:[]
        }
    },
    mounted() {
        var boton = document.getElementById('app');
        boton.classList.add('preloader');
        
    },
    methods: {
        addCredit(){
            this.openCredit = true
            var boton = document.getElementById('loading')
            boton.classList.add('preloader-modal')
        },
        closeCredit(){
            this.openCredit = false
            var boton = document.getElementById('loading')
            boton.classList.remove('preloader-modal')
            var boton = document.getElementById('app')
            boton.classList.remove('preloader')
            this.listPayments()
            this.search()
        },
        actualityMonthly(dato){
            if(dato == true){
                this.search()
            }
        },
        globalfunction(dato){
            if(dato = true){
                this.search();
                this.listPayments();
                this.listCharges()
            }
        },
        changeCharge(){
            this.openCharge = true
            var boton = document.getElementById('loading')
            boton.classList.add('preloader-modal')
        },
        closeCharge(){
            this.openCharge = false
            var boton = document.getElementById('loading')
            boton.classList.remove('preloader-modal')
        },
        changeMonthly(){            
            var boton = document.getElementById('loading');
            boton.classList.add('preloader-modal')
            this.openMonthly = true
        },
        historyMonthly(){
            var boton = document.getElementById('loading');
            boton.classList.add('preloader-modal')
            this.openHistoryMonthly = true
        },
        closeMonthlyHistory(click){
            var boton = document.getElementById('loading');
            boton.classList.remove('preloader-modal')
            this.openHistoryMonthly = false
        },
        closeMonthly(){
            this.openMonthly = false
            var boton = document.getElementById('loading');
            boton.classList.remove('preloader-modal')
        },
        closeAdvisor(click){
            var boton = document.getElementById('loading');
            boton.classList.remove('preloader-modal')
            this.openHistoryAdvisor = false
        },
        closeStatus(click){
            var boton = document.getElementById('loading');
            boton.classList.remove('preloader-modal')
            this.openHistoryStatus = false
        },
        saveAdvisor(){
            axios.post('/api/changeadvisor',{
                advisor_id: this.advisor,
                id: this.global.id,
                session_id: this.global.layout.id
            })
            .then(response => {
                if(response.status == 200){
                    this.search()
                    this.advisorstate = false
                }
            })
            .catch(err => {
                console.error(err); 
            })
        },
        saveStatus(){
            axios.post('/api/changestatus',{
                status: this.status,
                id: this.global.id,
                session_id: this.global.layout.id
            })
            .then(response => {
                if(response.status == 200){
                    this.search()
                    this.statusstate = false
                }
            })
            .catch(err => {
                console.error(err); 
            })
        },
        changeAdvisor(){
            this.advisorstate = !this.advisorstate
            this.listadvisors()
        },
        historyAdvisor(){
            var boton = document.getElementById('loading');
            boton.classList.add('preloader-modal')
            this.openHistoryAdvisor = true
        },
        changeStatus(){
            this.statusstate = !this.statusstate
        },
        historyStatus(){
            var boton = document.getElementById('loading');
            boton.classList.add('preloader-modal')
            this.openHistoryStatus = true
        },
        search(){
            axios.post('/api/searchaccount',{
                id: this.global.id
            })
            .then(response => {
                if(response.status == 200){
                    this.client = response.data[0]                    
                    this.advisor = this.client.advisor_id
                    this.status = this.client.status                     
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 2
                    })
                    setTimeout(() => {
                        var boton = document.getElementById('app');
                        boton.classList.remove('preloader')
                    }, 50);
                    if(this.client.monthly_amount != null){
                        var data = formatter.format(this.client.monthly_amount)
                        this.client.monthly_amount = data.replace('$', "")
                    }
                    if(this.client.total_charge != null){
                        var data = formatter.format(this.client.total_charge)
                        this.client.total_charge = data.replace('$', "")
                    }
                    if(this.client.total_payments != null){
                        var data = formatter.format(this.client.total_payments)
                        this.client.total_payments = data.replace('$', "")
                    }
                    if(this.client.balance != null){
                        var data = formatter.format(this.client.balance)
                        this.client.balance = data.replace('$', "")
                    }
                }
            })
            .catch(err => {
                console.error(err); 
            })
        },
        modalContract(id , index , session ,status , initial){            
            axios.post('/api/contractsales',{
                id:this.client.sale_id
            }).then(response => {
                if(response.status == 200){
                    this.contractsales = response.data[0]
                    console.log(this.contractsales)
                    this.openContract = true
                    var boton = document.getElementById('loading');
                    boton.classList.add('preloader-modal')
                }
            })            
        },
        modalPayment(){

            axios.post('/api/paymentsales',{
                id:this.client.sale_id
            }).then(response => {
                if(response.status == 200){
                    this.payments = response.data[0]
                    this.openPayment = true
                    var boton = document.getElementById('loading');
                    boton.classList.add('preloader-modal')
                }
            })
        },
        closePayment(click){
            var boton = document.getElementById('loading');
            boton.classList.remove('preloader-modal')
            this.openPayment = false
        },
        closeContract(click){
            var boton = document.getElementById('loading');
            boton.classList.remove('preloader-modal')
            this.openContract = false
            this.search('desc',11);
        },
        listPayments(){
            axios.post('/api/listpayment',{
                id:this.global.id
            })
            .then(response => {
                if(response.status == 200){
                    this.listpayments = response.data
                    this.listpayments.map(index => {
                        const formatter = new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 2
                        })
                        if(index.amount != null){
                            var data = formatter.format(index.amount)
                            index.amount = data.replace('$', "")
                        }
                    })
                }
            })
            .catch(err => {
                console.error(err); 
            })
        },
        listCharges(){
            axios.post('/api/listcharges',{
                id:this.global.id
            })
            .then(response => {
                if(response.status == 200){
                    this.listcharges = response.data
                    this.listcharges.map(index => {
                        const formatter = new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 2
                        })
                        if(index.amount != null){
                            var data = formatter.format(index.amount)
                            index.amount = data.replace('$', "")
                        }
                    })
                }
            })
            .catch(err => {
                console.error(err); 
            })
        },
        listadvisors(){
            var modulese=0;
            console.log(this.client.program_id)
            switch (this.client.program_id) {
                case '1': modulese=3;break;
                case '2': modulese=7;break;
                case '3': modulese=6;break;
                case '4': modulese=5;break;
                case '5': modulese=8;break;
            } 
            
            axios.post('/api/usersprograms',{id:modulese})
            .then(response => {
                this.advisors =  response.data 
            });            
        }

    },
    created() {
        this.search();
        this.listPayments();
        this.listCharges()
        //this.listadvisors()
    },
}
</script>