<template>
    <div>        
        <div class="row" style="padding: 20px">
            <div class="col-lg-5">                                                    
                <div class="form-group">
                    <label for="">Task</label>
                    <table class="table">
                        <tbody id="busq-user">
                            <tr class="th-bg">
                                <th>Subject</th>
                                <th>Date/Hour</th>
                                <th>Actions</th>
                            </tr>
                            <tr v-for="(item, index) in allTasks" :key="index">
                                <th>{{item.subject}}</th>
                                <th>{{item.date | myGlobalDay}}</th>
                                <th style="display: inline-flex">
                                    <i class="fas fa-check-double" style="font-size: 18px;color: green;" @click="checkTask(item.id,index)"></i>                                    
                                    <img :src="'/images/edit.png'" @click="editTask(item.id)" style="margin-left:5px">
                                    <img :src="'/images/delete.png'" @click="deleteTask(item.id)" style="margin-left:5px">
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="form-group">
                    <button class="btn class-button-green" @click="alltasks" style="float:left">View completed tasks</button>
                    <button class="btn class-button-green" @click="addTask" style="float:right">+ Add Task</button>
                </div>
            </div>
            <div class="col-lg-7">
                <div class="form-group">
                    <label for="">Notes</label>
                    <table class="table">
                        <tbody id="busq-user">
                            <tr class="th-bg">
                                <th>Jan</th>
                                <th>Feb</th>
                                <th>Mar</th>
                                <th>Abr</th>
                                <th>May</th>
                                <th>Jun</th>
                                <th>Jul</th>
                                <th>Agu</th>
                                <th>Set</th>
                                <th>Oct</th>
                                <th>Nov</th>
                                <th>Dec</th>
                            </tr>
                            <tr v-for="(item, index) in notes" :key="index">
                                <th>{{item.Jan}}</th>
                                <th>{{item.Feb}}</th>
                                <th>{{item.Mar}}</th>
                                <th>{{item.Abr}}</th>
                                <th>{{item.May}}</th>
                                <th>{{item.Jun}}</th>
                                <th>{{item.Jul}}</th>
                                <th>{{item.Agu}}</th>
                                <th>{{item.Set}}</th>
                                <th>{{item.Oct}}</th>
                                <th>{{item.Nov}}</th>
                                <th>{{item.Dec}}</th>                                
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="form-group">
                    <span>First Note of Seller <img title="Notes" :src="'/images/icons/CRM--notes-ON.ico'" alt=""  style="cursor:pointer" @click="notesSale"></span>
                    <button class="btn class-button-green" style="float:right" @click="addNotes">+ Add Note</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:['idaccount','session','allTasks','allNotes'],
        data() {
            return {
                notes:null,
            }
        },
        mounted() {

        },
        methods: {
            alltasks(){
                this.$emit('alltask',true)
            },
            addTask(){
                this.$emit('addtask',true)
            },
            notesSale(){
                this.$emit('notessale',true)
            },
            addNotes(){
                this.$emit('addnotes',true)
            },
            checkTask(id,index){
                var x = document.getElementById('app')
                x.classList.add('preloader')
                swal.fire({
                    title: 'Are you sure ?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#ab9220',
                    cancelButtonColor: '#8f9194',
                    confirmButtonText: 'Yes'
                    }).then((result) => {
                        if (result.value) {
                            axios.post('/api/checktaskcredit',{
                                id: id
                            })
                            .then(response => {
                                if(response.status == 200){
                                    x.classList.remove('preloader') 
                                    swal.fire({
                                    type: 'success',
                                    title: 'Operation Finish'
                                    }).then( (res) => {
                                        if(res){
                                            this.$emit('actultask',true)
                                            }
                                        });
                                }
                            })
                            .catch(err => {
                                console.error(err); 
                            })
                        }else{
                        }
                    })
            },
            editTask(id){
                axios.post('/api/edittaskcredit',{
                    id: id
                })
                .then(response => {
                    if(response.status == 200){
                        this.$emit('editTask',response.data[0])
                        /*var datos = {'id' : 1 ,'subject' : 'holas' , 'date' : '2018-12-12', 'hour' : '12:30' , 'content' : 'sdnvsonvsonvdosnvsnvlsnlknvlks'}
                        this.$emit('editTask', datos)*/
                    }
                })
                .catch(err => {
                    console.error(err); 
                })
            },
            deleteTask(id){
                var x = document.getElementById('app')
                x.classList.add('preloader')
                swal.fire({
                    title: 'Are you sure ?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#ab9220',
                    cancelButtonColor: '#8f9194',
                    confirmButtonText: 'Yes'
                    }).then((result) => {
                        if (result.value) {
                            axios.post('/api/deletetaskcredit',{
                                id: id
                            })
                            .then(response => {
                                if(response.status == 200){      
                                    x.classList.remove('preloader')                              
                                    swal.fire({
                                    type: 'success',
                                    title: 'Operation Finish'
                                    }).then( (res) => {
                                        if(res){
                                            this.$emit('actultask',true)
                                            }
                                        });
                                }
                            })
                            .catch(err => {
                                console.error(err); 
                            })
                        }else{
                            x.classList.remove('preloader')     
                        }
                    })
            },
        },
        created() {
            
        },
    }
</script>