<template lang="pug">
div
    #modal_event.modal-event.estilo-modal.box-login(style="width: 800px !important;z-index: 7;")
        .row.title-modal-edit
            .col-lg-12.col-md-12(style="text-align:center")
                h3 ADD TASK
                i.fas.fa-times.icon-close-target(@click="closeModal")
        div
            #modalBody.modal-body
                div 
                    div.campos-detail-modal.row(style="margin: 0;")                        
                        div.col-lg-6
                            div.form-group.class-inline.row
                                div.class-campo-icon.col-md-4 Client
                                div.class-campo-text.col-md-8
                                    span(name="start" id="date") {{ nameClient }}
                        br
                        br
                        .col-lg-12.col-md-12
                            .form-group
                                table.table
                                    tbody#busq-user
                                        tr.th-bg
                                            th Subject
                                            th Date/Hour
                                        tr(v-for="item in notes" :key="item.id")
                                            td {{item.subject}}
                                            td {{item.date | myGlobalDay}}
                        div.cont-search-paginate(style="display: inline-flex")
                            span.class-span-1 {{ ((start_page - 1)*perpage +1)+'-'+( start_page == ultima_pagina  ? totalnotes : start_page*perpage ) }}
                            span(style="padding-top: 7px;") of
                            span.class-span-2 {{ totalnotes }}
                            button.btn.class-btn-next-prev(aria-label="Previous" @click="prev(start_page)" :style="start_page != 1 ? '' : 'background:#CCCCCC ; pointer-events:none'")
                                i.fas.fa-caret-left                                
                            button.btn.class-btn-next-prev(aria-label="Next" @click="next(next_page)"  :style="this.start_page != this.ultima_pagina ? '' : 'background:#CCCCCC ; pointer-events:none'")
                                i.fas.fa-caret-right
                                
</template>

<script>
    export default {
        props:['nameClient','idaccount','session'],
        data() {
            return {
                start_page: '',
                next_page: '',
                ultima_pagina :'',
                totalnotes:'',
                perpage:'',
                notes:[]
            }
        },
        mounted() {
            
        },
        methods: {
            closeModal(){
                this.$emit('click',false)
            },
            search(){
                axios.post('/api/alltaskmodal',{
                    id: this.idaccount,
                    user_id: this.session
                })
                .then(response => {
                    if(response.status == 200){
                        this.notes = response.data.data
                        this.start_page =  response.data.current_page;
                        this.perpage = response.data.per_page;
                        this.next_page = this.start_page+1;
                        this.ultima_pagina = response.data.last_page;
                        this.totalnotes = response.data.total;
                    }
                })
                .catch(err => {
                    console.error(err); 
                })
            },
            prev(start_p){
                var newval = start_p - 1;
                /*var boton = document.getElementById('app');
                boton.classList.add('preloader')*/
                axios.post('/api/alltaskmodal?page='+newval, {
                    id: this.idaccount,
                    user_id: this.session
                }).then(response => {
                    if(response.status == 200){
                        this.notes = response.data.data                
                        this.start_page =  response.data.current_page;
                        this.perpage = response.data.per_page;
                        if(this.start_page == this.ultima_pagina){
                            this.next_page = this.start_page;
                        }else{
                            this.next_page = this.start_page+1;
                        } 
                        this.ultima_pagina = response.data.last_page;
                        this.totalnotes = response.data.total;
                    }
                });
            },  
            next(next){
                /*var boton = document.getElementById('app');
                boton.classList.add('preloader')*/
                axios.post('/api/alltaskmodal?page='+next, {
                    id: this.idaccount,
                    user_id: this.session
                }).then(response => {
                    if(response.status == 200){
                        this.notes = response.data.data
                        this.start_page =  response.data.current_page;
                        this.perpage = response.data.per_page; 
                        this.ultima_pagina = response.data.last_page;
                        this.totalnotes = response.data.total;
                        if(this.start_page == this.ultima_pagina){
                            this.next_page = this.start_page;
                        }else{
                            this.next_page = this.start_page+1;
                        }
                    }
                });
            },
        },
        created() {
            this.search()
        },
    }
</script>