<template>
    <div>
        <div id="loading">
            <div>
                <div class="row ">
                    <div class="col-md-12">
                        <div class="cont-title">
                            <div class="row">
                                <div class="col-lg-6">
                                    <span>Clients</span>
                                </div>
                            </div>
                        </div>
                        <div class="cont-search-paginate">
                            <div class="cont-user-info">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="border-campo-user">
                                            <span class="title-modal">Client Information</span>
                                            <div class="row" style="padding: 20px">
                                                <div class="col-lg-4">
                                                    <div style="color:#B59200">
                                                        <div class="form-group class-inline row">
                                                            <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                <span>Client name</span>
                                                            </div>
                                                            <div class="class-campo-text col-md-8">
                                                                <span name="start" id="date"> {{client.client_name}} </span>
                                                            </div>
                                                        </div>
                                                        <div class="form-group class-inline row">
                                                            <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                <span>Account</span>
                                                            </div>
                                                            <div class="class-campo-text col-md-8">
                                                                <span name="start" id="date"> {{client.account}} </span>
                                                            </div>
                                                        </div>
                                                        <div class="form-group class-inline row">
                                                            <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                <span>Mobile</span>
                                                            </div>
                                                            <div class="class-campo-text col-md-8">
                                                                <span name="start" id="date"> {{client.mobile}} </span>
                                                            </div>
                                                        </div>
                                                        <div class="form-group class-inline row">
                                                            <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                <span>Email</span>
                                                            </div>
                                                            <div class="class-campo-text col-md-8">
                                                                <span name="start" id="date"> {{client.email}} </span>
                                                            </div>
                                                        </div>                                                        
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div style="" class="row">                                                        
                                                        <div class="col-md-12">
                                                            <div class="form-group class-inline row">
                                                                <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                    <span>Start date</span>
                                                                </div>
                                                                <div class="class-campo-text col-md-8">
                                                                    <span name="start" id="date">{{ client.start_date | myGlobal}} </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="form-group class-inline row">
                                                                <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                    <span>Advisor</span>
                                                                </div>
                                                                <div class="class-campo-text col-md-8">                                                                
                                                                    <span name="start" id="date"> {{client.advisor_name}} </span>
                                                                    <i title="History" @click="historyAdvisor" class="fas fa-list" :style="advisorstate == false ? 'position: absolute;right: 5px;top: 10px;':'position: absolute;right: 5px;bottom: 10px;opacity:1 !important'"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="form-group class-inline row">
                                                                <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                    <span>Captured by</span>
                                                                </div>
                                                                <div class="class-campo-text add-class-campo-text col-md-8">
                                                                    <span name="start" id="date"> {{ client.captured_name }} </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="form-group class-inline row">
                                                                <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                    <span>Seller</span>
                                                                </div>
                                                                <div class="class-campo-text add-class-campo-text col-md-8">
                                                                    <span name="start" id="date"> {{client.seller_name}} </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div style="color:#B59200">
                                                        <div class="form-group class-inline row">
                                                            <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                <span >Status</span>
                                                            </div>
                                                            <div class="class-campo-text col-md-8">
                                                                <span name="start" id="date"> {{client.status == 1 ? 'Active' : 'Inactive'}} </span>                                                                    
                                                                <i title="History" @click="historyStatus" class="fas fa-list" :style="statusstate == false ? 'position: absolute;right: 5px;top: 10px;':'position: absolute;right: 5px;bottom: 10px;opacity:1 !important'"></i>
                                                            </div>
                                                        </div>
                                                        <div class="form-group class-inline row">
                                                            <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                <span>Initial payment</span>
                                                            </div>
                                                            <div class="class-campo-text col-md-8">
                                                                <img title="Initial Payment" :src="'/images/icons/CRM--initialpayment-ON.ico'" alt=""  style="cursor:pointer" @click="modalPayment()">
                                                            </div>
                                                        </div>
                                                        <div class="form-group class-inline row">
                                                            <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                <span>Contract fee</span>
                                                            </div>
                                                            <div class="class-campo-text col-md-8">
                                                                <img title="Contract Fee" :src="'/images/icons/CRM--contractfee-ON.ico'" alt=""  style="cursor:pointer" @click="modalContract()">
                                                            </div>
                                                        </div>
                                                        <div class="form-group class-inline row">
                                                            <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                <span>Monthly payment</span>
                                                            </div>
                                                            <div class="class-campo-text col-md-8">
                                                                <span>$ {{client.monthly_amount}} </span> <span :style="client.rbp == 0 ? 'color:#FF0000;font-weight: bold' : 'color:#00CC00;font-weight: bold'"> {{client.type_payment}}</span>                                                                
                                                                <i @click="historyMonthly" class="fas fa-list" style="position: absolute;right: 5px;top: 10px;"></i>                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cont-user-info">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="border-campo-user">
                                            <span class="title-modal">Options</span>
                                            <nav>
                                                <div class="nav nav-tabs nav-program" id="nav-tab" role="tablist" style="height:35px">
                                                    <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true" style="text-align: center; margin: 0px 3px; border-radius: 5px 5px 0px 0px;">Tasks / Notes</a>
                                                    <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false" style="text-align: center; margin: 0px 3px; border-radius: 5px 5px 0px 0px;">Payments</a>
                                                    <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-files" role="tab" aria-controls="nav-profile" aria-selected="false" style="text-align: center; margin: 0px 3px; border-radius: 5px 5px 0px 0px;">Files</a>
                                                </div>
                                            </nav>
                                            <div class="tab-content" id="nav-tabContent">
                                                <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="home-tab" style="border: 1px solid rgb(175, 175, 175); padding: 15px;">
                                                    <cont-task-notes-credit @editTask="this.editTask" @actultask="actualTask" :allTasks="this.tasks" :allNotes="this.notes" @alltask="alltask" @addtask="addtask" @notessale="notessale" @addnotes="addnotes" :idaccount="this.global.id" :session="this.global.layout.id" ></cont-task-notes-credit>
                                                </div>
                                                <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="profile-tab" style="border: 1px solid rgb(175, 175, 175); padding: 15px;">
                                                    <cont-payment-credit :totalcharges="client.total_charge" :totalpayments="client.total_payments" :balance="client.balance" :listpayments="listpayments" :listcharges="listcharges"></cont-payment-credit>
                                                </div>
                                                <div class="tab-pane fade" id="nav-files" role="tabpanel" aria-labelledby="profile-tab" style="border: 1px solid rgb(175, 175, 175); padding: 15px;">
                                                    <cont-files-credit :allFiles="this.files" :sessiom="this.global.layout.id" :account="this.global.id" @click="actulFiles"></cont-files-credit>
                                                </div>                                        
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal-payment v-if="openPayment" @click="closePayment" v-bind:payments="this.payments" :nameProgram="this.client.program_name" :nameClient="this.client.client_name" :type="1" :editmodal="false" :statusSale="4" :sessionId="0" :valorInitalPaymetn="0"></modal-payment>
        <modal-contract v-if="openContract" @click="closeContract" v-bind:contractsales="this.contractsales" :nameProgram="this.client.program_name" :nameClient="this.client.client_name" :type="1" :editmodal="false" :statusSale="4" :valorInitalPaymetn="0"></modal-contract>
        <modal-notas      v-if="openNotes"      @click="closeNotas"      v-bind:notesprogram="this.notesprogram"   :nameProgram="this.client.program_name" :nameClient="this.client.client_name" :type="1" :editmodal="false" :statusSale="4" :sourcesname="this.client.sourcesname" :pack="this.client.pack"></modal-notas>        
        <modal-add-charge v-if="openCharge" @click="closeCharge" :nameProgram="this.client.program_name" :nameClient="this.client.client_name" :lead_id="this.client.lead_id" :id="this.client.id" :account="this.client.account" :session="this.global.layout.id" @dato="globalfunction" :cantcards="this.client.cards"></modal-add-charge>
        <modal-history-advisor v-if="openHistoryAdvisor" @click="closeAdvisor" :nameProgram="this.client.program_name" :nameClient="this.client.client_name" :id="this.global.id"></modal-history-advisor>
        <modal-history-status v-if="openHistoryStatus" @click="closeStatus" :nameProgram="this.client.program_name" :nameClient="this.client.client_name" :id="this.global.id"></modal-history-status>
        <modal-history-monthly v-if="openHistoryMonthly" @click="closeMonthlyHistory" :nameProgram="this.client.program_name" :nameClient="this.client.client_name" :id="this.global.id"></modal-history-monthly>

        <modal-add-task :addtaskmodal="this.addtaskmodal" v-if="openAddTask" @click="closeAddTask" :nameClient="this.client.client_name" :idaccount="this.global.id" :session="this.global.layout.id"></modal-add-task>
        <modal-add-alltask v-if="openAllTask" @click="closeAllTask" :nameClient="this.client.client_name" :idaccount="this.global.id" :session="this.global.layout.id"></modal-add-alltask>
        <modal-add-note :addnotemodal="this.addnotemodal" v-if="openAddNotes" @click="closeAddNotes" :nameClient="this.client.client_name" :idaccount="this.global.id" :session="this.global.layout.id"></modal-add-note>
        <modal-add-allnote v-if="openAllNotes" @click="closeAllNotes"></modal-add-allnote>


    </div>
</template>

<script>
export default {
    props:['global'],
    data() {
        return {
            openContract: false,
            openPayment: false,
            openMonthly: false,
            openCharge: false,
            openHistoryAdvisor:false,
            openHistoryStatus:false,
            openHistoryMonthly:false,
            payments:[],
            client:[],
            advisors: [],
            advisor: '',
            advisorstate: false,
            status:'',
            statusstate: false,
            listpayments: [],
            listcharges: [],
            openCredit:false,
            contractsales:[],
            openNotes: false,
            openAllTask:false,
            openAddTask:false,
            openAllNotes:false,
            notes:[],
            openAddNotes:false,
            notesprogram:[],
            tasks:[],
            notes:[],
            addtaskmodal:{},
            addnotemodal:{},
            files:[]
        }
    },
    mounted() {
        var boton = document.getElementById('app');
        boton.classList.add('preloader');
    },
    methods: {
        actulFiles(){
            this.allfiles()
        },
        actualTask(){
            this.tasksgrilla()
        },
        tasksgrilla(){
            axios.post('/api/tasksgrilla',{
                id: this.global.id,
                user_id: this.global.layout.id
            })
            .then(response => {
                if(response.status == 200){
                    this.tasks = response.data.data
                }
            })
            .catch(err => {
                console.error(err); 
            })
        },
        alltask(){
            this.openAllTask= true
            var boton = document.getElementById('loading')
            boton.classList.add('preloader-modal')            
        },
        closeAllTask(){
            this.openAllTask = false
            var boton = document.getElementById('loading')
            boton.classList.remove('preloader-modal')
        },
        addtask(){
            this.openAddTask = true
            var boton = document.getElementById('loading')
            boton.classList.add('preloader-modal')            
            this.addtaskmodal = {'id' : '' ,'subject' : '' , 'date' : '', 'hour' : '' , 'content' : ''}
        },
        editTask(data){            
            this.openAddTask = true
            var boton = document.getElementById('loading')
            boton.classList.add('preloader-modal')
            this.addtaskmodal = data
        },
        closeAddTask(){
            this.openAddTask = false
            var boton = document.getElementById('loading')
            boton.classList.remove('preloader-modal')
            this.tasksgrilla()
        },
        notessale(){            
            axios.post('/api/notessales',{
                id:this.client.sale_id
            }).then(response => {
                if(response.status == 200){
                    this.notesprogram = response.data
                    this.openNotes = true;
                    var boton = document.getElementById('loading');
                    boton.classList.add('preloader-modal')
                }
            })
        },
        closeNotas(){            
            var boton = document.getElementById('loading');
            boton.classList.remove('preloader-modal')
            this.openNotes = false            
        },
        addnotes(){
            this.openAddNotes = true
            var boton = document.getElementById('loading');
            boton.classList.add('preloader-modal')
            this.addnotemodal = {'id' : '' , 'date' : '' , 'content' : ''}
        },
        closeAddNotes(){
            this.openAddNotes = false
            var boton = document.getElementById('loading');
            boton.classList.remove('preloader-modal')
        },
        /*openAllNotes(){
            this.openAllNotes = true
        },
        closeAllNotes(){
            this.openAllNotes = false
        },*/
        actualityMonthly(dato){
            if(dato == true){
                this.search()
            }
        },
        globalfunction(dato){
            if(dato = true){
                this.search();
                this.listPayments();
                this.listCharges()
            }
        },
        historyMonthly(){
            var boton = document.getElementById('loading');
            boton.classList.add('preloader-modal')
            this.openHistoryMonthly = true
        },
        closeMonthlyHistory(click){
            var boton = document.getElementById('loading');
            boton.classList.remove('preloader-modal')
            this.openHistoryMonthly = false
        },
        closeMonthly(){
            this.openMonthly = false
            var boton = document.getElementById('loading');
            boton.classList.remove('preloader-modal')
        },
        closeAdvisor(click){
            var boton = document.getElementById('loading');
            boton.classList.remove('preloader-modal')
            this.openHistoryAdvisor = false
        },
        closeStatus(click){
            var boton = document.getElementById('loading');
            boton.classList.remove('preloader-modal')
            this.openHistoryStatus = false
        },
        historyAdvisor(){
            var boton = document.getElementById('loading');
            boton.classList.add('preloader-modal')
            this.openHistoryAdvisor = true
        },
        changeStatus(){
            this.statusstate = !this.statusstate
        },
        historyStatus(){
            var boton = document.getElementById('loading');
            boton.classList.add('preloader-modal')
            this.openHistoryStatus = true
        },
        search(){
            axios.post('/api/searchaccount',{
                id: this.global.id
            })
            .then(response => {
                if(response.status == 200){
                    this.client = response.data[0]                    
                    this.advisor = this.client.advisor_id
                    this.status = this.client.status
                    var module=0;
                    switch (this.client.program_id) {
                        case 1: module=3;break;
                        case 2: module=7;break;
                        case 3: module=6;break;
                        case 4: module=5;break;
                        case 5: module=8;break;
                    }
                    axios.post('/api/usermodule/'+module,{ 
                        roles : '[2,3]',
                        type : '1'
                    }).then(response => {
                            this.advisors =  response.data 
                        });
                    setTimeout(() => {
                        var boton = document.getElementById('app');
                        boton.classList.remove('preloader')
                    }, 50); 
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 2
                    })
                    if(this.client.monthly_amount != null){
                        var data = formatter.format(this.client.monthly_amount)
                        this.client.monthly_amount = data.replace('$', "")
                    }
                    if(this.client.total_charge != null){
                        var data = formatter.format(this.client.total_charge)
                        this.client.total_charge = data.replace('$', "")
                    }
                    if(this.client.total_payments != null){
                        var data = formatter.format(this.client.total_payments)
                        this.client.total_payments = data.replace('$', "")
                    }
                    if(this.client.balance != null){
                        var data = formatter.format(this.client.balance)
                        this.client.balance = data.replace('$', "")
                    }
                }
            })
            .catch(err => {
                console.error(err); 
            })
        },
        modalContract(id , index , session ,status , initial){            
            axios.post('/api/contractsales',{
                id:this.client.sale_id
            }).then(response => {
                if(response.status == 200){
                    this.contractsales = response.data[0]
                    console.log(this.contractsales)
                    this.openContract = true
                    var boton = document.getElementById('loading');
                    boton.classList.add('preloader-modal')
                }
            })            
        },
        modalPayment(){

            axios.post('/api/paymentsales',{
                id:this.client.sale_id
            }).then(response => {
                if(response.status == 200){
                    this.payments = response.data[0]
                    this.openPayment = true
                    var boton = document.getElementById('loading');
                    boton.classList.add('preloader-modal')
                }
            })
        },
        closePayment(click){
            var boton = document.getElementById('loading');
            boton.classList.remove('preloader-modal')
            this.openPayment = false
        },
        closeContract(click){
            var boton = document.getElementById('loading');
            boton.classList.remove('preloader-modal')
            this.openContract = false
            this.search('desc',11);
        },
        listPayments(){
            axios.post('/api/listpayment',{
                id:this.global.id
            })
            .then(response => {
                if(response.status == 200){
                    this.listpayments = response.data
                    this.listpayments.map(index => {
                        const formatter = new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 2
                        })
                        if(index.amount != null){
                            var data = formatter.format(index.amount)
                            index.amount = data.replace('$', "")
                        }
                    })
                }
            })
            .catch(err => {
                console.error(err); 
            })
        },
        listCharges(){
            axios.post('/api/listcharges',{
                id:this.global.id
            })
            .then(response => {
                if(response.status == 200){
                    this.listcharges = response.data
                    this.listcharges.map(index => {
                        const formatter = new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 2
                        })
                        if(index.amount != null){
                            var data = formatter.format(index.amount)
                            index.amount = data.replace('$', "")
                        }
                    })
                }
            })
            .catch(err => {
                console.error(err); 
            })
        },
        allfiles(){
            axios.post('/api/listfiles',{
                id:this.global.id
            })
            .then(response => {
                if(response.status == 200){
                    this.files= response.data
                }
            })
            .catch(err => {
                console.error(err); 
            })
        }

    },
    created() {
        this.search();
        this.listPayments();
        this.listCharges()
        this.tasksgrilla()
        this.allfiles()
    },
}
</script>