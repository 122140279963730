<template lang="pug">
div
    #modal_event.modal-event.estilo-modal.box-login(style="width: 800px !important;z-index: 7;")
        .row.title-modal-edit
            .col-lg-12.col-md-12(style="text-align:center")
                h3 INITIAL PAYMENT
                i.fas.fa-times.icon-close-target(@click="closeModal")
        div
            #modalBody.modal-body
                div 
                    div.campos-detail-modal.row(style="margin: 0;")
                        div.col-lg-6
                            div.form-group.class-inline.row
                                div.class-campo-icon.col-md-4 Program
                                div.class-campo-text.col-md-8
                                    span(name="start" id="date") {{ program }}
                        div.col-lg-6
                            div.form-group.class-inline.row
                                div.class-campo-icon.col-md-4 Client
                                div.class-campo-text.col-md-8
                                    span(name="start" id="date") {{ client_name }}
                        br
                        br
                        .col-lg-4.col-md-4
                            .form-group.row
                                .col-md-4
                                    label Ammount
                                .col-md-8(style="display:inherit") $
                                    input.input-card.input-form(:id="'campo'+1" type="text" v-model="ammount" v-on:blur="validInitial(1)" @keypress="justNumbers" :disabled="valorEdit || this.payments.program_id == 2" step="any")
                                    span(v-if="errorAmmount" style="color:red") Required
                        .col-lg-8.col-md-8
                            .form-group.row
                                .col-md-4(style="margin-right: -15px")
                                    label Method of Payment
                                    br
                                    span(v-if="errorMethod" style="color:red") Required
                                .col-md-8(style="background: #f1f1f1;padding: 5px 15px;border-radius: 5px;")
                                    .row
                                        .col-lg-4(style="display:inherit")
                                            input(style="margin-top: 5px;margin-right: 2px;" type="radio" name="method" id="card" v-model="method_payment" value="0" @click="cardEvent(0)" :disabled="valorEdit")
                                            label(for="card" ) Credit Card
                                        .col-lg-4(style="display:inherit")
                                            input( style="margin-top: 5px;margin-right: 2px;" type="radio" name="method" id="cash" v-model="method_payment" value="1" @click="cardEvent(1)" :disabled="valorEdit")
                                            label(for="cash" ) Others                        
                        .col-lg-12.col-md-12(v-if="credit" :style=" cards != null ? cards.length > 3 ? 'height: 120px;margin-bottom:15px;margin-top: 15px;overflow-y: auto;padding-right: 0;padding-left: 0;':'margin-top: 15px;padding-right: 0;padding-left: 0;':'margin-top: 15px;padding-right: 0;padding-left: 0;'")
                            table.table.table-hover.table-striped
                                tbody(style="border: 1px solid #ccc;")
                                    tr(style="background: #f1f1f1;color: #999999;")
                                        th 
                                        th Card Holder Name
                                        th Card Number
                                        th MM
                                        th YY
                                        th CVC
                                    tr(v-for="(card, index) in cards" :key="index" )
                                        td 
                                            input(type="radio" :value="card.id" :name="'card'+card.id" v-model="card_id" :disabled="valorEdit")
                                        td(style="color: #9b9b9b;text-transform: capitalize") {{card.cardholdername}}
                                        td(style="color: #9b9b9b;") {{'XXXX-XXXX-XXXX-'+card.cardnumber}}
                                        td(style="color: #9b9b9b;") {{card.card_expi_month}}
                                        td(style="color: #9b9b9b;") {{card.card_expi_year}}
                                        td(style="color: #9b9b9b;") {{card.cardsecuritycode.length == 3 ? 'XX'+card.cardsecuritycode.substr(2) : 'XXX'+card.cardsecuritycode.substr(3)}}
                        .col-lg-12.col-md-12(style="text-align: right" v-if="credit" v-show="valorEdit != true")
                            a(@click="createcard" class="btn class-button-green" style="width:80px;color:white") #[img(:src="'/images/icon-add.png'" style="margin-right: 5px;width: 18%;margin-top: -3px;")] ADD
                                span(v-if="errorCD" style="color:red;position: absolute;left: 15px;top: 25px;") Required
                        .col-lg-.col-md-6(v-if="others")
                            .form-group
                                label Type #[span(v-if="errorTypes" style="color:red") Required]
                                select(v-model="types" class="input-form" :disabled="valorEdit")
                                    option(value="1") Cash
                                    option(value="2") Check
                                    option(value="3") Money Order
                        .col-lg-.col-md-6(v-if="others")
                            .form-group
                                label Serial Number #[span(v-if="errorSpecify" style="color:red") Required]
                                    input.input-card.input-form(v-model="specify" style="background: #f1f1f1 !important" :disabled="valorEdit")                                
                        .col.lg-12.col-md-12
                            div(v-if="sendMessage")
                                span(style="color: red") Process 
                                div.lds-ellipsis
                                    div
                                    div
                                    div
                                    div                            
                        .col-lg-12.col-md-12(v-if="errosList")
                            .form-group(style="color:red")
                                span(v-if="responseCode == 1") Approved
                                span(v-if="responseCode == 2") Declined
                                span(v-if="responseCode == 3") Error
                                span(v-if="responseCode == 4") Held for Review
                                ul
                                    li(v-for="(items , index) in errosAutorize" :key="index") {{items.errorText}}
                        .col-lg-12.col-md-12(v-if="messageList")
                            .form-group(style="color:green")
                                span(v-if="responseCode == 1") Approved
                                span(v-if="responseCode == 2") Declined
                                span(v-if="responseCode == 3") Error
                                span(v-if="responseCode == 4") Held for Review
                                ul
                                    li(v-for="(items , index) in messageAutorize" :key="index") {{items.description}}
            .modal-footer
                .form-group
                    button.btn.btn-success.btn-color-green(type="button" @click="savePayment" v-if="valorEdit != true" style="font-size: 13px;padding: 6px;height: 35px;") #[i.fas.fa-arrow-up] SUBMIT
    modal-card-create(v-if="createmodal" :idlead="this.lead_id" @new="addCard" @click="closedModalCar" style="z-index:99")            

</template>

<style lang="stylus">
    .text-style
        border: 1px solid #ccc;
        text-align: center;
        margin-left: 5px;
        width: 100%;
        height: 70px;
        border-radius: 10px;
</style>


<script>
export default {
    props:['payments','nameProgram','nameClient','type','editmodal','statusSale','sessionId','valorInitalPaymetn'],
    data() {
        return {
            sendMessage: false,
            sendMessageOk: false,
            program: this.nameProgram,
            client_name:this.nameClient,
            ammount: this.payments.amount,
            valorEdit : false,
            method_payment: this.payments.type_payment == null || this.payments.type_payment == 0 ? this.payments.type_payment : 1,
            credit: false,
            cash: false,
            others: false,
            cards: JSON.parse(this.payments.cards),
            card_id:this.payments.card_id,
            lead_id: this.payments.lead_id,
            createmodal:false,
            specify : this.payments.specify,
            errosAutorize: [],
            messageAutorize: [],
            errosList: false,
            messageList: false,
            responseCode: '',
            errorAmmount:false,
            errorCD:false,
            errorSpecify: false,
            errorMethod: false,
            errorTypes: false,
            types:this.payments.type_payment
        }
    },
    mounted() {
        if(this.payments.card_id != null && this.payments.card_id != ''){
            var cards = JSON.parse(this.payments.cards)
            var newcards = []
            cards.map(index => {
                if(index.id == this.payments.card_id){
                    newcards.push(index)
                }
            })
            this.cards = newcards            
        }

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        })
        if(this.ammount != null){
            var data = formatter.format(this.ammount)
            this.ammount = data.replace('$', "")
        }
        if(this.method_payment == 0){
            this.credit = true
            this.others = false
        }else if(this.method_payment == 1){
            this.credit = false
            this.others = true
        }
        if(this.type == 1 || this.editmodal == false || this.statusSale == 2 || this.statusSale == 4 || this.valorInitalPaymetn == 2){
            this.valorEdit = true
        }else{
            this.valorEdit = false
        }
    },
    methods: {
        closeModal(){
            this.$emit('click',false)
        },
        validInitial(id){
            this.ammount = this.globalFunction(id)
        },
        globalFunction(id){
            var x = document.getElementById('campo'+id).value            
            if(x.indexOf('.') != -1){ 
                var numw = x.replace(/,/gi, "");
                var dec = numw.split('.')
                var num = dec[0].split(/(?=(?:\d{3})+$)/).join(",");
                num = num+'.'+dec[1]
                
            }else{
                var numw = x.replace(/,/gi, "");
                var num = numw.split(/(?=(?:\d{3})+$)/).join(",");
                num = num+'.00'
            }
            return num
        },
        justNumbers: function (event) {
            var charCode = window.event ? event.which : event.keyCode;
            var RE = /^\d*(\.\d{1})?\d{0,1}$/;
            if (charCode != 46 && charCode > 31 
            && (charCode < 48 || charCode > 57)) {
                //Usando la definición del DOM level 2, "return" NO funciona.
                event.preventDefault();
            }
        },
        cardEvent(id){            
            if(id == 0){
                this.credit = true
                this.others = false
            }else if (id == 1){
                this.credit = false
                this.others = true
            }
            this.method_payment = id
        },
        createcard(){
            this.createmodal = true;
            var x = document.getElementById('modal_event')
            x.classList.add('preloader-new')
        },
        closedModalCar(click){
            this.createmodal = false
            var x = document.getElementById('modal_event')
            x.classList.remove('preloader-new')
        },
        addCard(cards) {
            this.cards = cards;
        },
        savePayment(){
            console.log(this.specify)
            console.log(this.types)
            if(this.ammount == null && this.method_payment == null){
                this.errorAmmount = true
                this.errorMethod = true
            }else if(this.ammount == null && this.method_payment != null){
                this.errorAmmount = true
                this.errorMethod = false 
            }else if(this.ammount != null && this.method_payment == null){
                this.errorAmmount = false
                this.errorMethod = true
            }else if(this.ammount != null && this.method_payment != null){
                this.errorAmmount = false
                this.errorMethod = false
                if(this.method_payment == 0){
                    if( this.ammount != null && this.card_id == null){
                        this.errorAmmount = false
                        this.errorCD = true                        
                    }else if (this.ammount != null && this.card_id != null){
                        this.errorAmmount = false
                        this.errorCD = false                        
                        this.sendValidatePaynent(this.method_payment)
                    }
                }else if (this.method_payment == 1){
                    if((this.specify == '' ||  this.specify == null) && (this.types == 0 || this.types == null)){
                        this.errorTypes = true
                        this.errorSpecify = true
                    }else if ((this.specify != '' &&  this.specify != null) && (this.types == 0 || this.types == null)){
                        this.errorTypes = true
                        this.errorSpecify = false
                    }else if ((this.specify == '' ||  this.specify == null) && (this.types != 0 || this.types != null)){
                        this.errorTypes = false
                        this.errorSpecify = true
                    }else if ((this.specify != '' &&  this.specify != null) && (this.types != 0 || this.types != null)){
                        this.errorTypes = false
                        this.errorSpecify = false
                        this.sendValidatePaynent(this.method_payment)
                    }
                }
            }
        },
        sendValidatePaynent(type){
            if(type == 0){                
                var x = document.getElementById('modal_event')
                x.classList.add('preloader-new')
                swal.fire({
                    title: 'Initial Payment',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#ab9220',
                    cancelButtonColor: '#8f9194',
                    confirmButtonText: 'Yes'
                    }).then((result) => {
                        if (result.value) {
                            this.sendMessage = true
                            var x = document.getElementById('app')
                            x.classList.add('preloader')
                            axios.post('/saveinitial',{
                                amount: this.ammount,
                                idcard: this.card_id,
                                method_payment: this.method_payment ,
                                specify: this.specify,
                                sale_id: this.payments.sale_id,
                                user_id: this.sessionId,
                                program_id: this.payments.program_id
                            }).then(response => {
                                if(response.status == 200 && response.data.status == 200) {
                                    this.messageAutorize = response.data.transaction.messages.message
                                    this.responseCode = response.data.transaction.responseCode 
                                    this.messageList = true
                                    this.errosList = false
                                    this.sendMessage = false
                                    swal.fire({
                                        type: 'success',
                                        title: 'Transaction Aproved'
                                        }).then( (res) => {
                                            if(res){
                                                this.$emit('click',false)
                                                }
                                            });
                                }else if(response.status == 200 && response.data.status == 500){
                                    if(response.data.transaction.errors){
                                        this.errosAutorize = response.data.transaction.errors.error
                                        this.responseCode = response.data.transaction.responseCode
                                        this.messageList = false
                                        this.errosList = true
                                        this.sendMessage = false
                                        x.classList.remove('preloader')
                                        var va = document.getElementById('modal_event')
                                        va.classList.remove('preloader-new')
                                    }else{
                                        this.messageAutorize = response.data.transaction.messages.message
                                        this.responseCode = response.data.transaction.responseCode 
                                        this.messageList = true
                                        this.errosList = false
                                        this.sendMessage = false
                                        x.classList.remove('preloader')
                                        var va = document.getElementById('modal_event')
                                        va.classList.remove('preloader-new')
                                    }
                                }
                            }).catch(error => {
                                if(error.response.status == 500 ) {
                                    this.errosAutorize = response.data.transaction.errors.error
                                    this.responseCode = response.data.transaction.responseCode
                                    this.errosList = true
                                    this.sendMessage = false
                                    x.classList.remove('preloader')
                                        var va = document.getElementById('modal_event')
                                        va.classList.remove('preloader-new')
                                }
                            })
                        }else{
                            var x = document.getElementById('modal_event')
                            x.classList.remove('preloader-new')
                        }
                    });
            }else{                
                var x = document.getElementById('modal_event')
                x.classList.add('preloader-new')
                swal.fire({
                    title: 'Initial Payment',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#ab9220',
                    cancelButtonColor: '#8f9194',
                    confirmButtonText: 'Yes'
                    }).then((result) => {
                        if (result.value) {
                            this.sendMessage = true
                            var x = document.getElementById('app')
                            x.classList.add('preloader')
                            axios.post('/api/saveinitial',{
                                amount: this.ammount,
                                idcard: this.card_id,
                                method_payment:  this.method_payment == 0 ? this.method_payment : this.types,
                                specify: this.specify,
                                sale_id: this.payments.sale_id,
                                user_id: this.sessionId,
                                program_id: this.payments.program_id
                            }).then(response => {
                                if(response.status == 200) {
                                    this.sendMessage = false
                                    swal.fire({
                                        type: 'success',
                                        title: 'Transaction Aproved'
                                        }).then( (res) => {
                                            if(res){
                                                this.$emit('click',false)
                                                }
                                            });
                                }
                            }).catch(error => {                    
                            })
                        }else{
                            var x = document.getElementById('modal_event')
                            x.classList.remove('preloader-new')
                        }
                    });  
            }
        },
    },
    created() {
        
    },
}
</script>