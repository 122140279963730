<template>
    <div>        
        <div class="row" style="padding: 20px">
            <div class="col-lg-12">
                <div class="">
                    <button class="btn class-button-green" @click="docutSubmit">Upload File</button>
                </div>
                <div v-if="formcreatedocu">
                    <form @submit.prevent="saveDocuments()" enctype="multipart/form-data" method="POST">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-lg-4">
                                    <label for="">Attach File <span v-if="erroCampo" style="color:red">Required</span></label>
                                </div>
                                <div class="col-lg-8">
                                    <input type="file" name="" id="" @change="onDocSubmit">
                                    <input type="hidden" name="" v-model="validatecampo">
                                </div>
                            </div>
                        </div>                                                
                        <div class="form-group" v-if="progressbar">                            
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped " role="progressbar" :style="'width:'+numeroAcco+'%'" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" > {{numeroAcco+'%'}}</div>
                            </div>
                        </div>
                        <br>
                        <div class="form-group">
                            <button type="submit" class="btn class-button-submit">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-12">                                                    
                <div class="form-group">                    
                    <table class="table">
                        <tbody id="busq-user">
                            <tr class="th-bg">
                                <th>Name</th>
                                <th>Size</th>
                                <th>Upload</th>
                                <th>Upload By</th>
                                <th>Actions</th>
                            </tr>
                            <tr v-for="(item, index) in allFiles" :key="index">
                                <th>
                                    <img style="width: 20px" v-if="item.extension == 'pdf'" :src="'/images/icons/pdf.png'" alt="">
                                    <img style="width: 20px" v-if="item.extension == 'docx'" :src="'/images/icons/doc.png'" alt="">
                                    <img style="width: 20px" v-if="item.extension == 'exe' || item.extension == 'csv' " :src="'/images/icons/excel.png'" alt="">
                                    <img style="width: 20px" v-if="item.extension == 'png' || item.extension == 'jpg' || item.extension == 'jpeg'" :src="'/images/icons/pdf.png'" alt="">
                                    <a :id="'linkdowlaod'+index"  :href="'/descargararchivo?route='+item.route+'&file='+item.file_name" style="cursor:pointer">{{item.file_name}}</a>
                                    <div :id="'formrename'+index" class="ocult-form"> 
                                        <input type="text" name="" :id="'textmew'+index">
                                        <button class="btn btn-success" @click="saveNew(index,item.route,item.file_name,item.id,item.extension)">Save</button>                                 
                                    </div>
                                </th>
                                <th>{{item.size}}</th>
                                <th>{{item.updated_at}}</th>
                                <th>{{item.user_name}}</th>                                
                                <th style="display: inline-flex">
                                    <span>
                                        <a v-if="item.updater_id == sessiom" @click="renameFile(index,item.file_name)">Rename</a>
                                        <span v-if="item.updater_id == sessiom"> | </span> 
                                        <a v-if="item.updater_id == sessiom" @click="deletefile(item.id,item.file_name)">Delete</a> 
                                        <span v-if="item.updater_id == sessiom"> | </span> 
                                        <a href="#">View</a>
                                        <span> | </span>
                                        <a :href="'/descargararchivo?route='+item.route+'&file='+item.file_name">Download</a>
                                    </span>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="stylus">
    .ocult-form
        display none
</style>


<script>
    export default {
        props:['allFiles','sessiom','account'],
        data() {
            return {
                formcreatedocu: false,
                form: new Form({
                    image:'',
                    namedoc:'',
                    user_id: this.sessiom,
                    typedoc:'',
                    account:this.account,
                    size:''
                }),
                validatecampo:'',
                erroCampo:'',
                numeroAcco:0,
                progressbar:false,
                namefile:'',
                newfile:'',
                routefile:'',
                renameVal: true
            }
        },   

        mounted() {
        },
        methods: {
            renameFile(index,name){
                var doc = document.getElementById('linkdowlaod'+index)
                var form = document.getElementById('formrename'+index)
                doc.classList.toggle('ocult-form')
                form.classList.toggle('ocult-form')
                var doc = document.getElementById('textmew'+index)
                var namevalor= name.split('.')
                doc.value = name.replace('.'+namevalor[namevalor.length - 1] , '')
            },
            saveNew(index,route,file,id,extension){
                var doc = document.getElementById('textmew'+index)
                axios.post('/renamefile',{
                    file: file,
                    route: route,
                    newfile: doc.value,
                    id:id,
                    user_id:this.sessiom,
                    extension:extension
                })
                .then(response => {
                    if(response.status == 200) {
                        var doc = document.getElementById('linkdowlaod'+index)
                        var form = document.getElementById('formrename'+index)
                        doc.classList.remove('ocult-form')
                        form.classList.add('ocult-form')
                        this.$emit('click',true)
                    }
                })
                .catch(err => {
                    console.error(err); 
                })
            },
            deletefile(id){
                axios.post('/deletefile',{
                    id:id,
                    user_id:this.sessiom,
                })
                .then(response => {
                    if(response.status == 200){                        
                        this.$emit('click',true)
                    }
                })
                .catch(err => {
                    console.error(err); 
                })
            },
            docutSubmit(){
                this.formcreatedocu = !this.formcreatedocu
            },
            onDocSubmit(e){
                var fileReader = new FileReader()

                fileReader.readAsDataURL(e.target.files[0])

                fileReader.onload = (e) => {
                    this.form.image = e.target.result
                }
                const file = e.target.files
                this.form.namedoc=  file[0].name 
                this.form.size = file[0].size
                this.numeroAcco = 0

                this.validatecampo =  file[0].name
                var newval = this.validatecampo.split('.')
                this.form.typedoc = newval[newval.length -1]
                
            },
            saveDocuments(){
                if(this.validatecampo == ''){
                    this.erroCampo = true
                }else{
                    this.erroCampo = false
                    this.progressbar = true
                    if(this.form.size < 999999){
                        var refresh = setInterval(this.nuevo, 800);
                    }else{
                        var refresh = setInterval(this.nuevo, 1200);
                    }
                    this.form.post('/savedocument',this.form)
                    .then(response => {                    
                        if(response.status == 200){                            
                            clearInterval(refresh)
                            this.numeroAcco = 100
                            this.$emit('click',true)
                        }
                    })
                    .catch(err => {
                        clearInterval(refresh)
                        this.numeroAcco = 0
                    })
                }
            },
            nuevo(){
                if(this.form.size < 999999){
                    this.numeroAcco = this.numeroAcco + 5
                }else{
                    this.numeroAcco = this.numeroAcco + 2
                }
            },
        },
        created() {
            
        },
    }
</script>