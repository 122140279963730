<template>
    <div class="row " style="margin-left: -9px;margin-right: -9px;">
        <div class="col-md-12">
            <div class="cont-title">
                <div class="row">
                    <div class="col-lg-6">
                        <span>import</span>
                    </div>
                    <div class="col-lg-6">                        
                        <button @click="returnList" class="btn class-button-red" style="margin-right: 5px;text-transform:uppercase"> <i class="fas fa-caret-left" style="margin-right:5px"></i> return</button>
                    </div>
                </div>
            </div>
            <div class="cont-search-paginate">
                <div class="row">
                    <div class="col-lg-12">
                        <div style="display: inline-flex">
                            <form @submit.prevent="processImport()">
                                <h3>Import</h3>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <label for="">Upload File <span v-if="valierror" style="color:red">Required</span></label>
                                        </div>
                                        <div class="col-lg-8">
                                            <input type="file" ref="file" name="file" @change="inpnutfile">
                                            <input type="hidden" name="" v-model="validatecampo">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <button class="btn class-button-green" style="float: left;text-transform:uppercase" type="submit" ><i class="fas fa-save" style="margin-right:5px"></i> Process</button>                                    
                                </div>
                            </form>
                            <a v-if="ocultimport" class="btn class-button-submit" style="color: white;position: absolute;right: 0;bottom: 0;text-transform:uppercase" @click="confirmarExcel"> <i class="far fa-check-circle" style="margin-right:5px"></i> SUBMIT</a>
                        </div>

                    </div>
                </div>
            </div>
            <div v-if="ocultimport">
                <div >
                    <label for="" style="margin-left: 2px;font-size: 17px;"> Number of items to Import : <span style="color: black;"> {{dataExcel != null || dataExcel != '' ? dataExcel[0].ct : '0' }} </span> </label>
                    <table class="table">
                        <tbody id="busq-user">
                            <tr class="th-bg">
                                <th>Transaction</th>
                                <th>Merchant</th>
                                <th>Client Authorize</th>
                                <th>Client A.M.G.</th>
                                <th>Account</th>
                                <th>Program</th>
                                <th>Advisor</th>
                                <th>Amount</th>
                                <th>Credit Card</th>
                                <th>Transaction Status</th>
                                <th>Settlement Date/Time</th>
                            </tr>
                            <tr v-for="(items , index) in dataExcel" :key="index" :class="[items.error_account == 1 ? 'color-yellow' : '']">
                                <td>{{items.transaction_id}}</td>
                                <td>
                                    <span v-if="items.merchant == 2">WWCE</span> 
                                    <span v-else>AMG</span>
                                </td>
                                <td>{{items.name_file}} {{items.first_name}}</td>
                                <td>{{items.name_bd}}</td>
                                <td>{{items.account}}</td>
                                <td>{{items.program}}</td>
                                <td>{{items.name_advisor}}</td>
                                <td>{{'$ '+items.amount}}</td>
                                <td>{{items.card_number}}</td>
                                <td>
                                    <span v-if="items.status == 'Settled Successfully' || items.status == 'Captured/Pending Settlement'" :class="[items.status == 'Settled Successfully' || items.status == 'Captured/Pending Settlement' ? 'color-green-text' : '']">{{items.status}}</span>
                                    <span v-else :class="[items.status != 'Settled Successfully' ? 'color-red-text' : '']">{{items.status}}</span>
                                </td>
                                <td>{{items.date}}</td>
                            </tr>
                        </tbody>
                    </table>                            
                </div>
            </div>
        </div>
    </div>
</template>


<style lang="stylus">
    .color-yellow
        background : #FF9800 !important
        color: black
    .color-green-text
        color: green
    .color-red-text
        color: red
</style>

<script>
    export default {
        props:['global','type'],
        data() {
            return {
                dataExcel:[],
                merchant: 1,
                ocultimport: false,
                datosenv:'',
                validatecampo:'',
                valierror: false,
                typedoc:''
            }
        },
        mounted() {
        },
        methods: {
            inpnutfile(e){
                var fileReader = new FileReader()

                fileReader.readAsDataURL(e.target.files[0])

                const file = e.target.files[0].name
                this.validatecampo =  file                
                this.typedoc = this.validatecampo.split('.')
            },
            returnList(){
                this.$emit('clickList',true)
            },
            confirmarExcel(){
                swal.fire({
                    title: 'ARE YOU SURE CONTINUE?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#ab9220',
                    cancelButtonColor: '#8f9194',
                    confirmButtonText: 'Yes'
                    }).then((result) => {
                        if (result.value) {
                            var boton = document.getElementById('app');
                            boton.classList.add('preloader');
                            axios.post('/processimport',{
                                id: this.global.layout.id
                            }).then(response => {
                                //this.returnList();
                                swal.fire({
                                    type: 'success',
                                    title: 'Import Finish',
                                    html: 
                                        'Transactions Successfull : '+response.data.successfull_transactions+'<br>'+
                                        'Transactions Declined : '+response.data.wrong_transactions,
                                }).then( (res) => {
                                    if(res){
                                        this.$emit('clickList',true)
                                        }
                                    });
                                setTimeout(() => {
                                    var boton = document.getElementById('app');
                                    boton.classList.remove('preloader')
                                }, 50); 
                            })
                        }
                    })
            },
            processImport(){ 
                    //this.datosenv = this.$refs.file.files[0]
                    this.formData = new FormData();
                    this.formData.append('file', this.$refs.file.files[0]);
                    this.formData.append('merchant',this.merchant);
                    this.dataExcel = []                    
                    if(this.validatecampo == ''){
                        this.valierror  = true
                    }else{
                        this.valierror  = false
                        if(this.typedoc[1] == 'xlsx' || this.typedoc[1] == 'csv' || this.typedoc[1] == 'txt'){
                            var boton = document.getElementById('app');
                            boton.classList.add('preloader');
                            axios.post('/importar', this.formData ,{
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            })
                            .then(response => {
                                if(response.status == 200){
                                    axios.post('/api/exceldetail',{
                                        id: this.global.layout.id
                                    }).then(response => {
                                            this.ocultimport = true
                                            this.dataExcel = response.data
                                            setTimeout(() => {
                                                var boton = document.getElementById('app');
                                                boton.classList.remove('preloader')
                                            }, 50); 
                                    })
                                }
                            })
                        }
                    }
            },
        },
        created() {            
        },
    }
</script>